.wizard {
    overflow: hidden;
    margin: 0 auto 0px auto;
    padding: 0 6px;
}

.wizard a {
    padding: 10px 8px 9px 8px;
    margin-right: -5px;
    border-top : 1px solid #309AFF;
    border-bottom : 1px solid #309AFF;
    background: white;
    position: relative;
    display: inline-block;
    font-size: 90%;
    width: 25%;
    width : calc(100% / 5);
    text-align: center;
    pointer-events: none;
}

.wizard a:hover {
    text-decoration: none;
}

.wizard a:before {
    width: 0px;
    height: 0px;
    border-top: 16px inset transparent;
    border-bottom: 16px inset transparent;
    position: absolute;
    content: "";
    top: 2px;
    left: -1px;
}

.wizard a:after {
    width: 0px;
    height: 0px;
    border-top: 16px inset transparent;
    border-bottom: 16px inset transparent;
    border-left: 10px solid white;
    position: absolute;
    content: "";
    top: 2px;
    right: -8px;
    z-index: 2;
    bottom: 2px;
}

.wizard a:first-child:before {
    border: none
}

.wizard a:last-child:after {
    border: none
}

.wizard a:first-child {
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    border-radius: 2px 0 0 4px;
}

.wizard a:last-child {
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    border-right : 1px solid #309AFF;
}

.wizard a span.badge {
    margin: 0 5px 0 18px;
    position: relative;
    top: -1px;
}

.wizard a span.text {
    display: none;
}

.wizard a:first-child .badge {
    margin-left: 0
}

.wizard .current {
    background: #007ACC;
    color: #fff;
}

.wizard .current:after {
    border-left-color: #007ACC
}


.wizard .done {
    background: #309AFF;
    color: #fff;
    pointer-events : auto;
}

.wizard .done:after {
    border-left-color: #309AFF
}


@media (min-width: 460px) {
    .wizard a span.text {
        display: block;
    }
}


@media (max-width: 768px) {
    .wizard a span.text {
        font-size: 80%;
    }
}

@media (max-width: 960px) {
    .wizard a {
        text-align: center;
    }
    .wizard a span.badge {
        margin-left: 0;
        margin-right: 0;
    }
}


@media (min-width: 960px) {
    .wizard a span.text {
        display: inherit;
    }
}

#panel-step-indicator {
    padding-top: 6px;
    padding-bottom: 6px;
}

a.popular-searches {
    white-space: nowrap;
}

/* Seat selection ---------------------------------- */

.pages-container {
    position: relative;
}


.coach-select-controls {
    width: 260px;
    max-width: 260px;
    margin: 0.5em auto 0 auto;
}

.coach-page-loading {
    text-align: center;
    margin-bottom: 2em;
}

.coach-page-loading img {
    width: 64px;
    height: 64px;
    margin: 2em auto;
    display: block;
}

.page-container .coach {
    width: 80%;
    margin: 0 auto 1em auto;
    cursor: pointer;
}

.page-container .coach:hover,
.page-container .coach:focus {
    -moz-box-shadow: 0px 0px 10px rgb(154, 207, 234);
    -webkit-box-shadow: 0px 0px 10px rgb(154, 207, 234);
    box-shadow: 0px 0px 10px rgb(154, 207, 234);
}

.coach-title {
    vertical-align: middle;
    line-height: 2em;
}


.seat-select-scheme {
    position: relative;
    width: 260px;
    min-height: 450px;
    margin: 0px auto;
    background-color: #F9F9F9;
    border-radius: 10px;
}

.compartment {
    margin: 0px;
    border: 3px solid #C5C5C5;
    padding: 0 5px 5px 5px;
    margin: 0 0 0 5px;
    width: 200px;
}

.compartment:first-child {
    margin-top: 5px;
    border-top-left-radius: 10px;
}

.compartment:last-child {
    margin-bottom: 5px;
    border-bottom-left-radius: 10px;
}

.compartment.compartment-single {
    border: none;
    width: auto;
}

.compartment.compartment-sleeping.compartment-sleeping-free {
    background-color: #FDFDFD;
}

.compartment.compartment-sleeping.compartment-sleeping-m {
    background-color: #CDE0E8;
}

.compartment.compartment-sleeping.compartment-sleeping-f {
    background-color: #F9DEDE;
}

.seat {
    position: absolute;
    display: block;
    text-align: center;
}

/*
    Seat types reside in different sprites

    Sprite organization:
        horizontal - seat state
            unavailable, available, selected, reserved
        vertical - orientation - clockwise, like CSS shortcuts for margin:;
            up(0), right(90), down(180), left(270)

*/
.seat-marker {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: block;
    position: absolute;
    /*background: transparent url(../img/sprite-seats.png) no-repeat;*/
}

.seat {
    display: block;
    line-height: 53px;
    /*background: transparent url(../img/sprite-seats.png) no-repeat;*/
}

.seat-type-normal {
    width: 53px;
    height: 53px;
}

.seat-type-sleeping {
    width: 165px;
    height: 53px;
}


.seat-type-normal.seat-o-0.seat-unavailable   { background-position:   -0px -149px; color: #999; }
.seat-type-normal.seat-o-90.seat-unavailable  { background-position:  -53px -149px; color: #999; }
.seat-type-normal.seat-o-180.seat-unavailable { background-position:   -0px -202px; color: #999; }
.seat-type-normal.seat-o-270.seat-unavailable { background-position:  -53px -202px; color: #999; }

.seat-type-normal.seat-o-0.seat-available     { background-position: -165px -149px; color: black; }
.seat-type-normal.seat-o-90.seat-available    { background-position: -218px -149px; color: black; }
.seat-type-normal.seat-o-180.seat-available   { background-position: -165px -202px; color: black; }
.seat-type-normal.seat-o-270.seat-available   { background-position: -218px -202px; color: black; }

.seat-type-normal.seat-o-0.seat-selected      { background-position: -330px -149px; color: black; }
.seat-type-normal.seat-o-90.seat-selected     { background-position: -383px -149px; color: black; }
.seat-type-normal.seat-o-180.seat-selected    { background-position: -330px -202px; color: black; }
.seat-type-normal.seat-o-270.seat-selected    { background-position: -383px -202px; color: black; }

.seat-type-normal.seat-o-0.seat-reserved      { background-position: -495px -149px; color: #999; }
.seat-type-normal.seat-o-90.seat-reserved     { background-position: -548px -149px; color: #999; }
.seat-type-normal.seat-o-180.seat-reserved    { background-position: -495px -202px; color: #999; }
.seat-type-normal.seat-o-270.seat-reserved    { background-position: -548px -202px; color: #999; }

.seat-number {
    font-size: 120%;
}

.seat-type-normal .seat-number {
    display: block;
    text-align: center;
}


.seat-type-sleeping {
    width: 165px;
    height: 55px;
    line-height: 55px;

    padding-right: 16px;
    text-align: right;
}


/* defaults are the same as orientation 270 */
.seat-type-sleeping.seat-o-90.seat-unavailable   { background-position: -0px -39px; color: #999; }
.seat-type-sleeping.seat-o-270.seat-unavailable  { background-position: -0px -93px; color: #999; }
.seat-type-sleeping.seat-unavailable             { background-position: -0px -93px; color: #999; }

.seat-type-sleeping.seat-o-90.seat-available     { background-position: -165px -39px; color: black; }
.seat-type-sleeping.seat-o-270.seat-available    { background-position: -165px -93px; color: black; }
.seat-type-sleeping.seat-available               { background-position: -165px -93px; color: black; }

.seat-type-sleeping.seat-o-90.seat-selected      { background-position: -330px -39px; color: #333; }
.seat-type-sleeping.seat-o-270.seat-selected     { background-position: -330px -93px; color: #333; }
.seat-type-sleeping.seat-selected                { background-position: -330px -93px; color: #333; }

.seat-type-sleeping.seat-o-90.seat-reserved      { background-position: -495px -39px; color: #333; }
.seat-type-sleeping.seat-o-270.seat-reserved     { background-position: -495px -93px; color: #333; }
.seat-type-sleeping.seat-reserved                { background-position: -495px -93px; color: #333; }


.seat-type-sleeping .seat-number {
    padding: 5px 5px;
}

.seat-type-sleeping .seat-marker.seat-location {
    right: 47px;
    top: 11px;
}

.seat-type-sleeping.seat-type-sleeping-top .seat-marker.seat-location {
    background-position: 0px 0px;
}

.seat-type-sleeping.seat-type-sleeping-middle .seat-marker.seat-location {
    background-position: -32px 0px;
}

.seat-type-sleeping.seat-type-sleeping-bottom .seat-marker.seat-location {
    background-position: -64px 0px;
}

.seat-type-sleeping .seat-marker.seat-gender {
    left: 16px;
    top: 11px;
    width: 18px;
}


.seat-type-sleeping.seat-unavailable .seat-marker,
.seat-type-sleeping.seat-unavailable .seat-number
{
    display: none;
}

.seat-type-sleeping.seat-available   .seat-marker.seat-gender-m { background-position: -100px -0px; }
.seat-type-sleeping.seat-available   .seat-marker.seat-gender-f { background-position: -118px -0px; }

.seat-type-sleeping.seat-selected    .seat-marker.seat-gender-m { background-position: -136px -0px; }
.seat-type-sleeping.seat-selected    .seat-marker.seat-gender-f { background-position: -154px -0px; }

.seat-type-sleeping.seat-reserved    .seat-marker.seat-gender-m { background-position: -172px -0px; }
.seat-type-sleeping.seat-reserved    .seat-marker.seat-gender-f { background-position: -190px -0px; }


/*.seat-unavailable {
    display: none;
}
*/

.seat-unavailable,
.seat-reserved {
    cursor: not-allowed;
}

.seat-available,
.seat-selected {
    cursor: pointer;
}

.seat-no-position {
    position: static;
    float: left;
    margin-right: 5px;
}

.seat-type-sleeping.seat-no-position {
    margin-top: 5px;
}


.seat-area {
    position: relative;
}

.seat-area-first { /* match .seat-select-scheme */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.seat-area-last { /* match .seat-select-scheme */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.seat-area-class-1 {
    background-color: #E9E9F3;
}

.seat-area-class-2 {
    background-color: #FFEEDD;
}

/* Passenger -------------------------------------- */

.passenger {
    display: block;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

button.passenger-select {
    color : #337ab7;
    background : transparent;
}

.passenger-select .passenger-gender {
    display: inline-block;
    height: 32px;
    width: 18px;
    background: transparent url(../images/sprite-seats.png) no-repeat;
    vertical-align: middle;
}

.passenger-select .passenger-gender-m { background-position: -100px -0px; }
.passenger-select .passenger-gender-f { background-position: -118px -0px; }

.passenger-seat-reserved {
    display: none;
}


/* Itinerary -------------------------------------- */

div.itinerary {
    font-size: 90%;
}

div.itinerary-trip,
#itinerary-tickets {
    margin-bottom: 1em;
}

div.itinerary-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
}

@media (max-width: 768px) {
    div.itinerary-label {
        text-align: left;
    }
}

/* Passengers ------------------------------------- */

.fieldset-passengers {
    position: relative;
}

.price-label-container {
    position: absolute;
    right: 10px;
    /*bottom: 1em;*/
}

.passenger-form {
    margin-bottom: 1em;
}

.passenger-form fieldset {
    padding: 10px 0;
    background-color: #FDFDFD;
}
.passenger-form .form-group {
    margin-bottom: 10px;
}

.passenger-btn-form-delete {
    margin-top: 6px;
    margin-right: 6px;
}


/* utils ------------------------------------------ */

.no-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
    padding-top : 2px;
}

.no-padding-left-right {
    padding-left: 0;
    padding-right: 0;
}

.centered-width-80p {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80%;
}


/* misc -------------------------------------------

.important-information {
  padding: 1px;
  color: red;
  border: solid;
  border-color: red;
  border-width: 1px;
  border-radius: 4px;
}
*/
