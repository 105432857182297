@import "~react-datepicker/dist/react-datepicker.css";
@import '~font-awesome/css/font-awesome.min.css';
@import 'lib/bdz.css';
@import 'lib/font.css';
@import 'lib/ticketing.css';
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
.pull--right {
  float: right;
}
.pull--left {
  float: left;
}
.hidden {
  display: none !important;
}
.hidden--children > * {
  display: none;
}
.text--left {
  text-align: left;
}
.text--center {
  text-align: center;
}
.text--right {
  text-align: right;
}
.text--lowercase {
  text-transform: lowercase;
}
.text--uppercase {
  text-transform: uppercase;
}
.display--b {
  display: block;
}
.display--i {
  display: inline;
}
.display--i-b {
  display: inline-block;
  font-size: 0;
}
.display--i-b * {
  font-size: 1rem;
}
.position--abs {
  position: absolute;
}
.position--rel {
  position: relative;
}
body.border-box * {
  box-sizing: border-box;
}
.border-box {
  box-sizing: border-box;
}
.center {
  margin-right: auto;
  margin-left: auto;
}
.center-h {
  left: 50%;
  transform: translateX(-50%);
}
.position--a {
  position: absolute;
}
.position--r {
  position: relative;
}
.position--top-left,
.position--t-l {
  top: 0;
  left: 0;
}
.position--top-right,
.position--t-r {
  top: 0;
  right: 0;
}
.position--bottom-left,
.position--b-l {
  bottom: 0;
  left: 0;
}
.position--bottom-right,
.position--b-r {
  bottom: 0;
  right: 0;
}
.keywords {
  font-weight: normal;
}
.btn-sticky-active {
  position: absolute;
  z-index: 3;
}
.passengers-navigation {
  background: #fff;
}
.btn-passenger {
  background-color: #fbfbfa;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
/*margin-right : 1px;*/
  line-height: 2.2em;
}
.btn-passenger-holder i {
  z-index: 3;
  position: absolute;
  top: 70%;
  left: 40%;
  color: #f00;
  background: #fff;
  border-radius: 50%;
}
.btn-passenger-holder {
  position: relative;
/*display : inline-block;*/
  float: left;
}
.btn-passenger.active {
  margin: 1px 0 0 1px;
  border-bottom: none !important;
  background-color: #fff;
}
.btn-passenger.active:focus,
.btn-passenger.active:active,
.btn-passenger:focus,
.btn-passenger:active {
  outline: none !important;
}
.fixed-at-top {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
}
.z-index-1 {
  z-index: 1;
}
.affix-top {
  position: relative;
}
.affix-bottom {
  position: absolute;
/*top : auto;*/
/*bottom : 0px;*/
}
.affix-bottom .active {
  z-index: 0 !important;
}
.affix-bottom i {
  top: 0;
}
.affix {
  margin-top: -1px;
  top: 1px;
  z-index: 1;
}
.train-information {
  margin-bottom: 15px;
}
.train-information-name {
  opacity: 0.4;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.train-information-name-active {
  opacity: 1;
}
.train-information-icon {
  position: relative;
  top: 2px;
/*font-size : 1.1em;*/
}
.passenger-form {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}
.option_custom {
  background-color: #fff;
}
.option_custom:hover {
  z-index: 1000;
}
#composition__holder svg g[class^='seat-']:hover,
#composition__holder svg g[class^='sleep-']:hover {
  fill: #e8e8e8;
  cursor: pointer;
}
#composition__holder svg g[class^='seat-']:hover rect,
#composition__holder svg g[class^='sleep-']:hover rect {
  fill: #e8e8e8;
  cursor: pointer;
}
#composition__holder svg g[class^='seat-'].green,
#composition__holder svg g[class^='sleep-'].green {
  fill: #b1febf !important;
}
#composition__holder svg g[class^='seat-'].green rect,
#composition__holder svg g[class^='sleep-'].green rect {
  fill: #b1febf !important;
}
#composition__holder svg g[class^='seat-'].open,
#composition__holder svg g[class^='sleep-'].open {
  fill: #fff;
}
#composition__holder svg g[class^='seat-'].open rect,
#composition__holder svg g[class^='sleep-'].open rect {
  fill: #fff;
}
#composition__holder svg g[class^='seat-'].taken,
#composition__holder svg g[class^='sleep-'].taken {
  fill: #f8d7b8;
}
#composition__holder svg g[class^='seat-'].taken rect,
#composition__holder svg g[class^='sleep-'].taken rect {
  fill: #f8d7b8;
}
#composition__holder svg g[class^='seat-'].reserved,
#composition__holder svg g[class^='sleep-'].reserved {
  fill: #b1febf;
}
#composition__holder svg g[class^='seat-'].reserved rect,
#composition__holder svg g[class^='sleep-'].reserved rect {
  fill: #b1febf;
}
#composition__holder svg g[class^='seat-'].red,
#composition__holder svg g[class^='sleep-'].red {
  fill: #f8d7b8;
}
#composition__holder svg g[class^='seat-'].red rect,
#composition__holder svg g[class^='sleep-'].red rect {
  fill: #f8d7b8;
}
#composition__holder svg g[class^='seat-'].male,
#composition__holder svg g[class^='sleep-'].male {
  fill: #00bcd4;
}
#composition__holder svg g[class^='seat-'].male rect,
#composition__holder svg g[class^='sleep-'].male rect {
  fill: #00bcd4;
}
#composition__holder svg g[class^='seat-'].female,
#composition__holder svg g[class^='sleep-'].female {
  fill: #f8b8d4;
}
#composition__holder svg g[class^='seat-'].female rect,
#composition__holder svg g[class^='sleep-'].female rect {
  fill: #f8b8d4;
}
#composition__holder svg g[class^='seat-'].together,
#composition__holder svg g[class^='sleep-'].together {
  fill: #ffeb3b;
}
#composition__holder svg g[class^='seat-'].together rect,
#composition__holder svg g[class^='sleep-'].together rect {
  fill: #ffeb3b;
}
.input-date[readonly] {
  background: #fff;
  cursor: pointer;
}
#form-ticketing ul.list-group {
  overflow: hidden;
}
.btn-passenger .passenger-text-short {
  display: initial;
}
.btn-passenger .passenger-text-long {
  display: none;
}
.btn-passenger + .passenger-btn-form-delete {
  display: none;
}
.btn-passenger.active .passenger-text-short {
  display: none;
}
.btn-passenger.active .passenger-text-long {
  display: initial;
}
.btn-passenger.active + .passenger-btn-form-delete {
  display: initial;
}
.text--right-i {
  text-align: right !important;
}
.btn-sticky-active {
  position: absolute;
  z-index: 3;
}
.passengers-navigation {
  background: #fff;
}
.btn-passenger {
  background-color: #fbfbfa;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
/*margin-right : 1px;*/
  line-height: 2.2em;
}
.btn-passenger-holder i {
  z-index: 3;
  position: absolute;
  top: 70%;
  left: 40%;
  color: #f00;
  background: #fff;
  border-radius: 50%;
}
.btn-passenger-holder {
  position: relative;
/*display : inline-block;*/
  float: left;
}
.btn-passenger.active {
  margin: 1px 0 0 1px;
  border-bottom: none !important;
  background-color: #fff;
}
.btn-passenger.active:focus,
.btn-passenger.active:active,
.btn-passenger:focus,
.btn-passenger:active {
  outline: none !important;
}
.fixed-at-top {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
}
.z-index-1 {
  z-index: 1;
}
.affix-top {
  position: relative;
}
.affix-bottom {
  position: absolute;
/*top : auto;*/
/*bottom : 0px;*/
}
.affix-bottom .active {
  z-index: 0 !important;
}
.affix-bottom i {
  top: 0;
}
.affix {
  margin-top: -1px;
  top: 1px;
  z-index: 1;
}
.train-information {
  margin-bottom: 15px;
}
.train-information-name {
  opacity: 0.4;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.train-information-name-active {
  opacity: 1;
}
.train-information-icon {
  position: relative;
  top: 2px;
/*font-size : 1.1em;*/
}
.bdz-icon {
/*color : lightgray;*/
  opacity: 0.4;
}
.info-route-holder-active {
/*background : #92c100;*/
  color: #fff;
  border-radius: 3px;
  background: #80b900;
  background: -webkit-gradient(linear, left top, right top, from(#80b900), to(#cede00));
  background: -webkit-linear-gradient(left, #80b900 60%, #cede00 100%);
  background: linear-gradient(to right, #80b900 60%, #cede00 100%);
}
.info-route-holder-active .bdz-icon {
  color: #fff;
  opacity: 0.6;
}
.bdz-icon-class-active {
/*color : black!important;*/
  opacity: 1 !important;
}
#register-form .denied-form {
  margin: 0;
}
.modal__holder {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
}
#loading_modal img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 100px / 2);
  left: calc(50% - 100px / 2);
}
.input-group-addon.add-on {
  cursor: pointer;
}
.input-station .invalid__control {
  border-color: #b94a48;
}
.react-datepicker-popper {
  z-index: 100;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  padding: 5px 10px;
  width: 100%;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
}
.control-language {
  border: none;
  background-color: rgba(255,255,255,0);
}
.control-language img {
  margin: 0 2px 0 0;
}
#header-mobile .control-language {
  padding: 3px 20px;
  width: 100%;
  text-align: left;
}
#header-mobile .control-language:hover {
  background-color: #e8e8e8;
  background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
}
.text-header--choose {
  font-weight: bold;
  text-align: center;
}
.routes {
  border: 4px solid #2b669a;
}
.routes.active {
  display: block;
}
.list-group-item {
  cursor: pointer;
}
.list-group-item.active > .row {
  color: #fff;
}
ul.routes {
  margin: 0 -15px -10px;
  padding: 0;
  color: #333;
  text-shadow: none;
}
#panel__schedule {
  overflow: initial;
}
#table-schedules-arrive,
#table-schedules-departure {
  color: #232e80;
  margin: 0;
}
#table-schedules-arrive thead,
#table-schedules-departure thead {
  background-color: #265a88;
  color: #fff;
}
.control-exit-profile {
  border: none;
}
.passengers-navigation {
  position: relative;
}
.wizard {
  text-align: center;
}
.wizard a {
  width: calc(100% / 4);
}
#panel-profile-tickets .panel-heading .btn-group-custom-tickets {
  margin: -25px 0 0 0;
}
.btn-custom-tickets-refresh {
/*margin-top : -100%;*/
  margin-right: 3px;
}
#loader_modal,
#passenger_modal {
  z-index: 2004;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.31);
}
#passenger_modal {
  background-color: rgba(255,255,255,0.9);
}
#passenger_modal .panel-heading {
  margin: 0;
  background-color: #f7f7f7;
}
#loader-2 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
}
#loader-2 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}
#loader-2 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}
#loader-2 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}
.homepage_info_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #b1b1b1;
  text-align: center;
}
.homepage_info_wrapper p {
  margin: 40px 0 35px;
}
.homepage_info_wrapper img {
  margin: 0 0 35px;
}
#composition__holder {
  text-align: center;
}
.seats_train_information .train-information-name {
  cursor: pointer;
}
.train-information-name {
  cursor: pointer;
}
.input-group.date .react-datepicker-wrapper .react-datepicker__input-container input.invalid_date {
  border-color: #b94a48;
}
.input-group.date label.invalid_date {
  border-color: #b94a48;
}
.legend_bdz_icons {
  display: flex;
}
.legend_bdz_icons .bdz_icons {
  margin-top: 2px;
}
@-moz-keyframes bounce {
  0%, 75%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-webkit-keyframes bounce {
  0%, 75%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-o-keyframes bounce {
  0%, 75%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes bounce {
  0%, 75%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
