@charset "UTF-8";

@font-face {
  font-family: "bdz";
  src:url("../fonts/bdz.eot");
  src:url("../fonts/bdz.eot?#iefix") format("embedded-opentype"),
    url("../fonts/bdz.woff") format("woff"),
    url("../fonts/bdz.ttf") format("truetype"),
    url("../fonts/bdz.svg#bdz") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "bdz" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="bdz-icon-"]:before,
[class*=" bdz-icon-"]:before {
  font-family: "bdz" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bdz-icon-1class:before {
  content: "1";
}
.bdz-icon-2class:before {
  content: "2";
}
.bdz-icon-velo:before {
  content: "v";
}
.bdz-icon-book:before {
  content: "m";
}
.bdz-icon-business:before {
  content: "b";
}
.bdz-icon-dog:before {
  content: "k";
}
.bdz-icon-sleep:before {
  content: "s";
}
.bdz-icon-reserved:before {
  content: "r";
}
.bdz-icon-tea:before {
  content: "t";
}
.bdz-icon-time:before {
  content: "i";
}
.bdz-icon-arrive:before {
  content: "a";
}
.bdz-icon-train:before {
  content: "l";
}
.bdz-icon-depart:before {
  content: "d";
}
.bdz-icon-pass:before {
  content: "p";
}
.bdz-icon-calendar:before {
  content: "c";
}
.bdz-icon-tarif:before {
  content: "e";
}
.bdz-icon-route:before {
  content: "w";
}
