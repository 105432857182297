/*@import url(http://fonts.googleapis.com/css?family=Open Sans:400,700&subset=latin,cyrillic,cyrillic-ext);*/

@font-face {
    font-family : 'Open_Sans';
    font-weight : 400;
    font-style : normal;

    /*src : url('../fonts/OpenSans-Regular-webfont.eot');*/
    /*src : url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),*/
    src : url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
    /*url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),*/
    /*url('../fonts/OpenSans-Regular-webfont.svg#Open_Sans') format('svg');*/
}

@font-face {
    font-family : 'Open_Sans';
    font-weight : 700;
    font-style : normal;

    /*src : url('../fonts/OpenSans-Bold-webfont.eot');*/
    /*src : url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),*/
    src : url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
    /*url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),*/
    /*url('../fonts/OpenSans-Bold-webfont.svg#Open_Sans') format('svg');*/
}

html, body {
    font-family : 'Open_Sans', Helvetica, Arial, sans-serif;
    font-size : 11pt;
    font-weight : 400;

    width : 100%;
    height : 100%;

    background : white;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family : inherit !important;
}

.container {
    background : #eee;
}

#header {
    position : relative;
    padding : 0;
    background : #fff;
    background : -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), to(#eceae6));
    background : -webkit-linear-gradient(top, #fff 50%, #eceae6 100%);
    background : linear-gradient(to bottom, #fff 50%, #eceae6 100%);
    -webkit-box-shadow : inset 0 15px 10px -10px rgba(0, 0, 0, .3);
            box-shadow : inset 0 15px 10px -10px rgba(0, 0, 0, .3);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceae6', GradientType=0 );
}

a#header-logo {
    font-weight : bold;
    display : block;
    float : left;
    width : 290px;
    height : 87px;
    margin : 20px 0 10px 0;
    padding : 70px 0 0 0;
    text-align : center;
    text-decoration : none;
    color : #2e3583 !important;
    background : url('../images/logo-bdz-md.png') 15px 12px no-repeat;
}

a#header-logo:hover {
    text-decoration : none;
}

h1#header-title {
    font-size : 150%;
    display : block;
    margin : 30px 0 0 0;
    text-decoration : none;
    color : #7a716d;
    text-shadow : 1px 1px 0 white;
}

div#header-line {
    position : absolute;
    top : 70px;
    right : 0;
    left : 300px;
    height : 10px;
    background : green;
    background : transparent; /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
    background : -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)),to(rgba(238,238,238,1)));
    background : -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(238,238,238,1) 100%);
    background : linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(238,238,238,1) 100%); /* W3C */

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#eeeeee',GradientType=1 ); /* IE6-9 */
}

#header-controls {
    position : absolute;
    right : 10px;
    bottom : 8px;
    height : 30px;
}
#header-controls .header-control {
    display : inline-block;
}
#header-controls a {
    font-size : 90%;
    line-height : 22px;
    display : inline-block;
    padding : 10px 5px 10px 55px;
    color : black !important;
    background : transparent url(../images/bg-menu-separator.png) 0 0 no-repeat;
}

#header-controls a:focus {
    text-decoration: none;
}

.control-language, .control-exit-profile {
    background : transparent url(../images/bg-menu-separator.png) 0 0 no-repeat;
    padding : 10px 5px 10px 55px;
}

button.control-language:active, button.control-language:focus {
    outline: none;
    border: none;
}

#header-mobile {
    padding : 5px 5px 12px 5px;
    background : #fff;
    background : -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), to(#eceae6));
    background : -webkit-linear-gradient(top, #fff 50%, #eceae6 100%);
    background : linear-gradient(to bottom, #fff 50%, #eceae6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceae6', GradientType=0 );
}

a#header-logo-mobile {
    font-weight : bold;
    display : inline-block;
    width : 80%;
    height : 25px;
    margin-top : 4px;
    padding-top : 2px;
    padding-left : 112px;
    color : #2e3583 !important;
    background : transparent url(../images/logo-bdz-xs.png) no-repeat;
}

a#header-logo-mobile:hover {
    text-decoration : none;
}
a#header-controls-mobile {
    display : relative;
}

a#header-controls-mobile span.icon-bar {
    border : 2px solid #007acc;
}
.lang-en {
    display : inline-block;
    width : 30px;
    height : 19px;
    background : url(../images/lang-en.png);
}

#content {
    padding-top : 15px;
    -webkit-box-shadow : inset 0 5px 5px -6px rgba(0, 0, 0, .3);
            box-shadow : inset 0 5px 5px -6px rgba(0, 0, 0, .3);
}

.panel {
    /* overflow : hidden; */
    border : 0;
    -webkit-box-shadow : 2px 2px 2px rgba(0, 0, 0, .15);
            box-shadow : 2px 2px 2px rgba(0, 0, 0, .15);
}

.panel-red > .panel-heading, .panel-blue > .panel-heading, .panel-green > .panel-heading, .panel-line > .panel-heading {
    margin : 0;
    padding : 0 0 5px 0;
    border : 0;
    background : white;
}

.panel-heading > h3 {
    font-size : 105%;
    font-weight : bold;
    line-height : 2em;
    margin : 0;
    padding : 0 15px;
    background : white;
}

.panel-heading > .helptext {
    line-height : 2em;
    float : right;
    padding : 0 15px;
    color : #333;
}

.panel-red > .panel-heading, .panel-heading.panel-heading-red {
    background : #fcb900;
    background : -webkit-gradient(linear, left top, right top, from(#fcb900),to(#dc5600));
    background : -webkit-linear-gradient(left, #fcb900 0%,#dc5600 100%);
    background : linear-gradient(to right, #fcb900 0%,#dc5600 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcb900', endColorstr='#dc5600',GradientType=1 );
}

.panel-green > .panel-heading, .panel-heading-panel.heading-green {
    background : #80b900;
    background : -webkit-gradient(linear, left top, right top, from(#80b900),to(#cede00));
    background : -webkit-linear-gradient(left, #80b900 0%,#cede00 100%);
    background : linear-gradient(to right, #80b900 0%,#cede00 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80b900', endColorstr='#cede00',GradientType=1 );
}

.panel-blue > .panel-heading, .panel-heading.panel-heading-blue {
    background : #1a85da;
    background : -webkit-gradient(linear, left top, right top, from(#1a85da),to(#29a));
    background : -webkit-linear-gradient(left, #1a85da 0%,#29a 100%);
    background : linear-gradient(to right, #1a85da 0%,#29a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a85da', endColorstr='#2299aa',GradientType=1 );
}

.panel-body > .list-unstyled > li {
    position : relative;
    padding-left : 20px;
}

.panel-body > .list-unstyled > li:after {
    position : absolute;
    top : 5px;
    left : 2px;
    width : 0;
    height : 0;
    content : ' ';
    pointer-events : none;
    border : 4px solid transparent;
}

.panel-red > .panel-body > .list-unstyled > li:after {
    border-left-color : #fcb900;
}

.panel-green > .panel-body > .list-unstyled > li:after {
    border-left-color : #80b900;
}

.panel-blue > .panel-body > .list-unstyled > li:after {
    border-left-color : #1a85da;
}

#panel-text-buy-ticket {
    min-height : 320px;
}


.breadcrumb {
    margin-bottom : 0;
    background-color : transparent;
}

.google-map-canvas, .google-map-canvas * {
    -webkit-box-sizing : content-box;
            box-sizing : content-box;
}

.google-map-canvas {
    height : 400px;
    -webkit-box-shadow : 0 0 4px rgba(0,0,0,.75);
            box-shadow : 0 0 4px rgba(0,0,0,.75);
}

.panel-train {
    background : white url('../images/train.png') right bottom no-repeat;
}

.input-date {
    text-align : center; /*max-width: 200px; */
}

#search .btn {
    margin-top : 25px;
}

#info .nav {
    margin-bottom : 20px;
    padding-left : 60px;
}



/*.list-group .glyphicon {
    margin-left: -7px;
    margin-right: 4px;
    background: #ebebeb;
    padding: 6px 20px 5px 4px;
    border-radius: 14px;
    line-height: 0.8em;
}
*/
/*.list-group .glyphicon-chevron-down {
    padding: 3px 3px 3px 3px
}
*/
/*.list-group .active .glyphicon {
    background: white;
    color: #428BCA;
}
*/
/*.list-group-item div {
    display: none;
    background: white;
    padding: 10px;
    border-radius: 4px;
    margin: 15px 10px 5px 10px;
    color: black;
    text-shadow: none;
    box-shadow: 0 0 4px rgba(0,0,0,0.5);
}
*/
.price {
    text-align : right;
}

.total {
    padding-top : 5px;
    border-top : 1px solid gray;
}

.trip-details {
    font-size : smaller;
}

#footer-line {
    margin-top : 1em;
    padding-top : 1em;
    border-top : 1px solid #fafafa;
}


.profile-row {
    line-height : 200%;
}

/* Bootstrap 3 customizations */

/* for django.contrib.messages */
.alert-error {
    color : #b94a48;
    border-color : #eed3d7;
    background-color : #f2dede;
}

.alert-error hr {
    border-top-color : #e6c1c7;
}

.alert-error .alert-link {
    color : #953b39;
}

legend {
    font-size : 120%;
    line-height : 180%;
    margin-bottom : 0;
    background : white;
}

.padding {
    padding : 1em;
}

.margin-bottom {
    margin-bottom : 1em;
}

.no-margin-bottom {
    margin-bottom : 0;
}

.no-margin-top {
    margin-top : 0;
}

.margin-left {
    margin-left : 1em;
}

.margin-left-double {
    margin-left : 2em;
}

.centered-80p {
    display : block;

    width : 80%;
    margin-right : auto;
    margin-left : auto;
}

.text-small {
    font-size : 80%;
}

.text-x-small {
    font-size : 70%;
}

.text-ellipsis {
    overflow : hidden;

    white-space : nowrap;
    text-overflow : ellipsis;
}

.border-right {
    border-right : 1px solid #eee;
}

.map-marker-label {
    font-weight : bold;
    z-index : 10000;
    display : block;
    padding : 3px;
    text-align : center;
    color : white;
    background : rgba(0,0,0, .5);
}
.select2-container .select2-choice .select2-arrow {
    border-left : 0;
    background : none;
    background-image : none;
}
.select2-container.form-control {
    padding : 0;
}
.select2-container .select2-choice {
    height : 100%;
    background-color : none;
    background-image : none;
}
.select2-dropdown-open .select2-choice {
    background-color : white;
    background-image : none;
}
.select2-search {
    padding : 0;
}
.datepicker td.day.disabled {
    text-decoration : line-through;
    color : #ffb8b8;
}
.routes {
    display : none;
    border : 4px solid #347ab6;
    background : #347ab6;
}
.routes li {
    list-style : none;
    border-bottom : 2px solid #347ab6;
    background : #fff;
}
.routes li:first-child {
    border-top-left-radius : 5px;
    border-top-right-radius : 5px;
}
.routes li:last-child {
    border-bottom-right-radius : 5px;
    border-bottom-left-radius : 5px;
}
.important-information {
    padding : 1px;
    color : #f00;
    border : solid;
    border-width : 1px;
    border-color : #f00;
    border-radius : 4px;
}
/*@media (max-width : 768px) {
    .panel:nth-child(1) {
        margin-top : 60px;
    }
}
header {
    z-index : 1;
    position : fixed;
    top : 0;
    right : 0;
    left : 0;
}*/

.btn-group-custom-tickets {
    overflow : hidden;
}
.btn-group-custom-tickets button {
    max-width : 25px;
    transition : all 1s ease;
}
.btn-group-custom-tickets button span {
    padding-left : 2px;
    opacity : 0;
    transition : all .4s ease;
}
.btn-group-custom-tickets button:focus {
    outline : none;
}
.btn-group-custom-tickets button:hover {
    max-width : 150px;
    transition : all 1s ease;
}
.btn-group-custom-tickets button:hover span {
    opacity : 1;
    transition : all 2s ease;
}
.custom-hide {
    display : none;
}
.custom-alert-error {
    color : #b94a48!important;
    border-color : #eed3d7!important;
    background-color : #f2dede!important;
}
.text-left-custom {
    text-align : left!important;
}
.text-center-custom {
    text-align : center!important;
}
.text-14-custom {
    font-size : 1.1em;
}

#loading-screen {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display : flex;
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
  -webkit-box-pack : center;
  -webkit-justify-content : center;
  -ms-flex-pack : center;
          justify-content : center;
  background : rgba(0,0,1,.1);
}

.reservation-timer-body span {
    color: #DD5A00;
    font-size: 2em;
    text-align: center;
}